/* eslint-disable no-unused-vars */
import React from 'react';
import Layout from '../components/layout/layout';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Formik } from 'formik';
import { Fonts, HeroAdv, ContentAdv } from '../global.css';
import BackToTop from '../components/back-to-top/back-to-top';
import FuzzySearchInput from '../components/location-autocomplete/fuzzy-search';
import {
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import BoxSec from '../components/checkbox/checkbox-section';
import Checkbox from '../components/checkbox/checkbox';
import Autocomplete from '../components/autocomplete/autocomplete';
import { navigate } from '@reach/router';
import LocationAutocomplete from '../components/location-autocomplete/autocomplete';
import { Popover } from '../components/checkbox/checkbox.css';
import { MasterDataContext } from '../store/masterDataContext';

class AdvancedSearch extends React.Component {
  static contextType = MasterDataContext;
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      distance: '',
      keySugestion: [],
      reset: false,
      locationField: true,
      showAlert: false,
    };
    this.userCountry = null;
  }
  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  componentDidMount() {
    const masterData =
      this.context && this.context.masterData ? this.context.masterData : {};

    let keySugestion = [];
    if (
      typeof window !== 'undefined' &&
      masterData &&
      Array.isArray(masterData.CONDITION) &&
      Array.isArray(masterData.PRODUCT)
    ) {
      this.userCountry =
        window.localStorage.getItem('test-user-country') ||
        window.localStorage.getItem('user-country') ||
        '';
      masterData.CONDITION.forEach(element => {
        keySugestion.push(element.DisplayValue);
        if (element.Children) {
          element.Children.forEach(subElement =>
            keySugestion.push(subElement.DisplayValue)
          );
        }
        masterData.PRODUCT.forEach(element => {
          if (!keySugestion.includes(element.DisplayValue))
            keySugestion.push(element.DisplayValue);
        });
      });
      this.setState({
        keySugestion: keySugestion,
      });
    }
  }

  prevMasterData = null;

  componentDidUpdate() {
    if (this.state.reset === true) {
      this.setState({ reset: false });
    }

    if (this.context) {
      const { masterData } = this.context;

      if (masterData !== this.prevMasterData) {
        this.prevMasterData = masterData;

        let keySugestion = [];
        if (
          typeof window !== 'undefined' &&
          masterData &&
          Array.isArray(masterData.CONDITION) &&
          Array.isArray(masterData.PRODUCT)
        ) {
          this.userCountry =
            window.localStorage.getItem('test-user-country') ||
            window.localStorage.getItem('user-country') ||
            '';
          masterData.CONDITION.forEach(element => {
            keySugestion.push(element.DisplayValue);
            if (element.Children) {
              element.Children.forEach(subElement =>
                keySugestion.push(subElement.DisplayValue)
              );
            }
            masterData.PRODUCT.forEach(element => {
              if (!keySugestion.includes(element.DisplayValue))
                keySugestion.push(element.DisplayValue);
            });
          });
          this.setState({
            keySugestion: keySugestion,
          });
        }
      }
    }
  }

  handleSubmit(values) {
    let { pageContext } = this.props;

    navigate(
      `/${pageContext.langKey}/results/?${Object.keys(values)
        .map(
          k =>
            encodeURIComponent(k) +
            '=' +
            encodeURIComponent(
              Array.isArray(values[k]) ? values[k].join('~') : `${values[k]}`
            )
        )
        .join('&')}`
    );
  }

  render() {
    let { data } = this.props;
    const masterData =
      this.context && this.context.masterData ? this.context.masterData : {};

    let statuses =
      masterData &&
      masterData.RECSTATUS &&
      masterData.RECSTATUS.sort((a, b) => a.SortOrder - b.SortOrder);
    let ages =
      masterData &&
      masterData.AGERANGE &&
      masterData.AGERANGE.sort((a, b) => a.SortOrder - b.SortOrder);
    let genders =
      masterData &&
      masterData.GENDER &&
      masterData.GENDER.sort((a, b) => a.SortOrder - b.SortOrder);
    let phases =
      masterData &&
      masterData.PHASE &&
      masterData.PHASE.sort((a, b) => a.SortOrder - b.SortOrder);
    let availableDocs =
      masterData && masterData.ATTACHTYPE && masterData.ATTACHTYPE
        ? masterData.ATTACHTYPE.sort((a, b) => a.SortOrder - b.SortOrder)
        : [];
    let patientData =
      masterData &&
      masterData.PATIENT_LEVEL_SHARING &&
      masterData.PATIENT_LEVEL_SHARING.sort(
        (a, b) => a.SortOrder - b.SortOrder
      );
    let studyType =
      masterData &&
      masterData.STUDY_TYPE &&
      masterData.STUDY_TYPE.sort((a, b) => a.SortOrder - b.SortOrder);
    return (
      <Layout meta={data.advancedSearchJson.seo}>
        <Fonts>
          <HeroAdv>
            <div>
              <h1>{data.advancedSearchJson.title}</h1>
              <p>{data.advancedSearchJson.heroContent}</p>
            </div>
          </HeroAdv>
          <ContentAdv>
            <Formik
              initialValues={{
                ageRange:
                  (this.searchParams &&
                    this.searchParams.get('ageRange') &&
                    this.searchParams.get('ageRange').split('~')) ||
                  [],
                phases:
                  (this.searchParams &&
                    this.searchParams.get('phases') &&
                    this.searchParams.get('phases').split('~')) ||
                  [],
                documents:
                  (this.searchParams &&
                    this.searchParams.get('documents') &&
                    this.searchParams.get('documents').split('~')) ||
                  [],
                Keyword:
                  (this.searchParams && this.searchParams.get('Keyword')) || '',
                studyType:
                  (this.searchParams && this.searchParams.get('studyType')) ||
                  [],
                gender:
                  (this.searchParams && this.searchParams.get('gender')) || [],
                HasCTGovResult:
                  (this.searchParams &&
                    this.searchParams.get('HasCTGovResult')) ||
                  [],
                patientLevel:
                  (this.searchParams &&
                    this.searchParams.get('patientLevel')) ||
                  [],
                SponsorUnits:
                  (this.searchParams &&
                    this.searchParams.get('SponsorUnits')) ||
                  [],
                Status:
                  (this.searchParams &&
                    this.searchParams.get('Status') &&
                    this.searchParams.get('Status').split('~')) ||
                  [],
                Latitude:
                  (this.searchParams &&
                    this.searchParams.get('Latitude') &&
                    this.searchParams.get('Latitude').split('~')) ||
                  [],
                Longitude:
                  (this.searchParams &&
                    this.searchParams.get('Longitude') &&
                    this.searchParams.get('Longitude').split('~')) ||
                  [],
                LocationName:
                  (this.searchParams &&
                    this.searchParams.get('LocationName') &&
                    this.searchParams.get('LocationName').split('~')) ||
                  [],
                MileRadius:
                  (this.searchParams && this.searchParams.get('MileRadius')) ||
                  '',
              }}
              onSubmit={(values, actions) => {
                if (this.state.locationField) {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                } else {
                  this.setState({
                    showAlert: true,
                  });
                }
              }}
              render={props => (
                <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
                  <div className={'search-section'}>
                    <Row>
                      <Col xl={4} lg={3} md={6}>
                        <h4>
                          {data.advancedSearchJson.search.keySearch}
                          <img
                            src={'/icons/tooltip.svg'}
                            alt={''}
                            id="keyword"
                          />
                        </h4>
                        <Popover
                          placement="top"
                          trigger="hover"
                          modifiers={{ flip: { behavior: ['bottom'] } }}
                          target="keyword"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                data.advancedSearchJson.tooltips.keySearch
                                  .childMarkdownRemark.html,
                            }}
                          />
                        </Popover>
                        <Autocomplete
                          placeholder={
                            data.advancedSearchJson.search.keySearchPH
                          }
                          suggestions={this.state.keySugestion}
                          name="Keyword"
                          changeValue={value => {
                            props.setFieldValue('Keyword', value);
                          }}
                          reset={this.state.reset}
                        />
                      </Col>
                      <Col xl={3} lg={3} md={6}>
                        <h4>{data.advancedSearchJson.search.location}</h4>
                        {this.userCountry === null ||
                        this.userCountry === 'CN' ? (
                          <FuzzySearchInput
                            lat={props.values.Latitude}
                            lng={props.values.Longitude}
                            locationName={props.values.LocationName}
                            changeValue={(lat, long, locationName) => {
                              props.setFieldValue(['Latitude'], lat);
                              props.setFieldValue(['Longitude'], long);
                              props.setFieldValue(
                                ['LocationName'],
                                locationName
                              );
                              props.setFieldValue('MileRadius', '100');
                              this.setState({
                                distance: '100 mi (160 km)',
                              });
                              if (lat.length === 0 && long.length === 0) {
                                props.setFieldValue('MileRadius', '');
                                this.setState({
                                  distance: '',
                                });
                              }
                            }}
                            showMessage={this.state.showAlert}
                            funcMessage={param => {
                              if (param === false) {
                                this.setState({
                                  locationField: param,
                                });
                              } else {
                                this.setState({
                                  locationField: param,
                                  showAlert: false,
                                });
                              }
                            }}
                          />
                        ) : (
                          <LocationAutocomplete
                            reset={this.state.reset}
                            changeValue={(lat, long, address) => {
                              props.setFieldValue(['Latitude'], lat);
                              props.setFieldValue(['Longitude'], long);
                              props.setFieldValue(['LocationName'], address);
                              props.setFieldValue('MileRadius', '100');
                              this.setState({
                                distance: '100 mi (160 km)',
                              });
                              if (lat.length === 0 && long.length === 0) {
                                props.setFieldValue('MileRadius', '');
                                this.setState({
                                  distance: '',
                                });
                              }
                            }}
                            showMessage={this.state.showAlert}
                            funcMessage={param => {
                              if (param === false) {
                                this.setState({
                                  locationField: param,
                                });
                              } else {
                                this.setState({
                                  locationField: param,
                                  showAlert: false,
                                });
                              }
                            }}
                          />
                        )}
                        {this.state.showAlert ? (
                          <p className={'warning-message'}>
                            {'Please select location'}
                          </p>
                        ) : null}
                      </Col>
                      <Col xl={2} lg={3} md={6}>
                        <h4>
                          {data.advancedSearchJson.search.distance}{' '}
                          <img
                            src={'/icons/tooltip.svg'}
                            alt={''}
                            id="distance"
                          ></img>
                        </h4>
                        <Popover
                          placement="top"
                          trigger="hover"
                          modifiers={{ flip: { behavior: ['bottom'] } }}
                          target="distance"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                data.advancedSearchJson.tooltips.distance
                                  .childMarkdownRemark.html,
                            }}
                          />
                        </Popover>
                        <Dropdown
                          isOpen={this.state.dropdownOpen}
                          toggle={this.toggleDropdown.bind(this)}
                          className={'select-dropdown'}
                        >
                          <DropdownToggle
                            disabled={
                              props.values.Latitude.length < 1 &&
                              props.values.Longitude.length < 1
                            }
                            tag="button"
                            type="button"
                          >
                            {this.state.distance || (
                              <p>{data.advancedSearchJson.search.distance}</p>
                            )}
                            <img src="/icons/dropdown_arrow.svg" alt="icon" />
                          </DropdownToggle>
                          <DropdownMenu
                            flip={false}
                            direction="down"
                            modifiers={{
                              setMaxHeight: {
                                enabled: true,
                                order: 890,
                                fn: data => {
                                  return {
                                    ...data,
                                    styles: {
                                      ...data.styles,
                                      overflow: 'auto',
                                      maxHeight: 300,
                                    },
                                  };
                                },
                              },
                            }}
                          >
                            {data.advancedSearchJson.distance.map(item => (
                              <DropdownItem
                                onClick={() => {
                                  this.setState({
                                    distance: item.value,
                                  });
                                  props.setFieldValue('MileRadius', item.key);
                                }}
                                key={item.key}
                                value={item.value}
                              >
                                {item.value}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                      <Col xl={3} lg={3} md={6} className={'action-section'}>
                        <button
                          type="button"
                          onClick={() => {
                            props.setFieldValue('MileRadius', '');
                            props.setFieldValue('Keyword', '');
                            props.setFieldValue(['Latitude'], '');
                            props.setFieldValue(['Longitude'], '');
                            props.setFieldValue(['LocationName'], '');
                            this.setState({
                              reset: true,
                              distance: '',
                            });
                          }}
                          className={'clearBTN'}
                        >
                          {data.advancedSearchJson.search.clear}
                        </button>
                        <button className={'searchBTN'}>
                          {data.advancedSearchJson.search.search}
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <Row style={{ marginTop: '-38px' }}>
                    {statuses && statuses.length && (
                      <Col
                        lg={3}
                        md={4}
                        xs={12}
                        className={'field-direction first-field'}
                      >
                        <BoxSec
                          title={data.advancedSearchJson.search.status}
                          items={statuses}
                          name={'Status'}
                          tooltip={data.advancedSearchJson.tooltips.studyStatus}
                        />
                      </Col>
                    )}
                    {phases && phases.length && (
                      <Col lg={3} md={4} xs={12} className={'filed-phases'}>
                        <BoxSec
                          title={data.advancedSearchJson.search.phases}
                          items={phases}
                          name={'phases'}
                          tooltip={data.advancedSearchJson.tooltips.phases}
                        />
                      </Col>
                    )}
                    {ages && ages.length && (
                      <Col
                        lg={3}
                        md={4}
                        xs={12}
                        className={'field-direction' + ' age-col'}
                      >
                        <BoxSec
                          title={data.advancedSearchJson.search.age}
                          items={ages}
                          name={'ageRange'}
                        />
                      </Col>
                    )}
                    {genders && genders.length && (
                      <Col lg={3} md={4} xs={12} className={'field-direction'}>
                        <div className={'box-sec'}>
                          <h4>{data.advancedSearchJson.search.gender}</h4>
                          <div className={'checkboxes-section'}>
                            {genders.map((item, index) =>
                              item.InternalValue.indexOf('ALL') > -1 ? null : (
                                <Checkbox
                                  key={index}
                                  id={item.InternalValue}
                                  value={item.InternalValue}
                                  displayvalue={item.DisplayValue}
                                  name={'gender'}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </Col>
                    )}
                    {studyType && studyType.length && (
                      <Col lg={3} md={4} xs={12} className={'filed-phases'}>
                        <BoxSec
                          title={data.advancedSearchJson.search.studyType}
                          items={studyType}
                          name={'studyType'}
                          tooltip={data.advancedSearchJson.tooltips.studyType}
                        />
                      </Col>
                    )}
                    {availableDocs && availableDocs.length ? (
                      <Col
                        lg={3}
                        md={4}
                        xs={12}
                        id={'documents-section'}
                        className={'field-direction'}
                      >
                        <BoxSec
                          title={data.advancedSearchJson.search.availableDoc}
                          items={availableDocs}
                          name={'documents'}
                        />
                      </Col>
                    ) : null}
                    {/* results section here
                      I have no ideea why this was implemented like this
                    */}
                    <Col
                      lg={3}
                      md={4}
                      xs={12}
                      className={'filed-phases tablet-margin'}
                    >
                      <div className={'box-sec'}>
                        <h4>{'Results'}</h4>
                        <div className={'checkboxes-section'}>
                          {/* study with results field
                            will send as param HasResults=true or empty
                          */}
                          <Checkbox
                            id={'withresults'}
                            value={'true'}
                            displayvalue={'Studies with Results'}
                            name={'HasCTGovResult'}
                          />
                          {/* study without results field
                              will send as param IsReleasedToCtGov=true or empty
                          */}
                          <Checkbox
                            id={'withoutresults'}
                            value={'false'}
                            displayvalue={'Studies without Results'}
                            name={'HasCTGovResult'}
                          />
                        </div>
                      </div>
                    </Col>
                    {patientData && patientData.length && (
                      <Col
                        lg={3}
                        md={4}
                        xs={12}
                        className={'filed-phases tablet-margin'}
                      >
                        <BoxSec
                          title={data.advancedSearchJson.search.patientData}
                          items={patientData}
                          name={'patientLevel'}
                          tooltip={data.advancedSearchJson.tooltips.patientData}
                        />
                        <div className={'box-sec'}>
                          <h4>{'Vaccine Studies'}</h4>
                          <div className={'checkboxes-section'}>
                            <Checkbox
                              id={'SponsorUnits'}
                              value={'SPON_UNIT_VACCINES'}
                              displayvalue={'Vaccine Studies Only'}
                              name={'SponsorUnits'}
                            />
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row className={'search-bottom'}>
                    <Col lg={3} md={6} className={'action-section mobile'}>
                      <button
                        type="button"
                        onClick={() => {
                          if (
                            typeof window != 'undefined' &&
                            window.innerWidth < 768
                          ) {
                            props.setFieldValue('patientLevel', []);
                            props.setFieldValue('HasCTGovResult', []);
                            props.setFieldValue('documents', []);
                            props.setFieldValue('studyType', []);
                            props.setFieldValue('gender', []);
                            props.setFieldValue('ageRange', []);
                            props.setFieldValue('phases', []);
                            props.setFieldValue('Status', []);
                            props.setFieldValue('MileRadius', '');
                            props.setFieldValue('Keyword', '');
                            props.setFieldValue(['Latitude'], '');
                            props.setFieldValue(['Longitude'], '');
                            this.setState({
                              reset: true,
                              distance: '',
                            });
                          } else {
                            props.setFieldValue('patientLevel', []);
                            props.setFieldValue('HasCTGovResult', []);
                            props.setFieldValue('documents', []);
                            props.setFieldValue('studyType', []);
                            props.setFieldValue('gender', []);
                            props.setFieldValue('ageRange', []);
                            props.setFieldValue('phases', []);
                            props.setFieldValue('Status', []);
                          }
                        }}
                        className={'clearBTN'}
                      >
                        {data.advancedSearchJson.search.clear}
                      </button>
                      <button className={'searchBTN'}>
                        {data.advancedSearchJson.search.search}
                      </button>
                    </Col>
                  </Row>
                </form>
              )}
            />
          </ContentAdv>
        </Fonts>
        <BackToTop light={true} />
      </Layout>
    );
  }
}

export default AdvancedSearch;

AdvancedSearch.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

export const query = graphql`
  query {
    advancedSearchJson {
      title
      heroContent
      seo {
        pageTitle
        pageDescription
      }
      search {
        keySearch
        keySearchPH
        location
        distance
        locationPH
        clear
        search
        status
        phases
        age
        gender
        availableDoc
        studyType
        patientData
      }
      tooltips {
        studyStatus {
          childMarkdownRemark {
            html
          }
        }
        keySearch {
          childMarkdownRemark {
            html
          }
        }
        studyType {
          childMarkdownRemark {
            html
          }
        }
        phases {
          childMarkdownRemark {
            html
          }
        }
        patientData {
          childMarkdownRemark {
            html
          }
        }
        distance {
          childMarkdownRemark {
            html
          }
        }
      }
      distance {
        key
        value
      }
    }
  }
`;
