import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './checkbox';
import { Popover } from './checkbox.css';

const BoxSec = ({ title, items, name, tooltip }) => (
  <div className={'box-sec'}>
    {tooltip ? (
      <Popover
        placement={'auto'}
        trigger="hover"
        modifiers={{ flip: { behavior: ['bottom'] } }}
        target={name}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: tooltip.childMarkdownRemark.html,
          }}
        />
      </Popover>
    ) : null}
    <h4>
      {title}
      {tooltip ? (
        <img src={'/icons/tooltip.svg'} alt={''} id={name}></img>
      ) : null}
    </h4>
    <div className={'checkboxes-section'}>
      {items.map((item, index) =>
        item.InternalValue.indexOf('RECSTATUS_RELIQUISHED') > -1 ? null : (
          <Checkbox
            key={index}
            id={item.InternalValue}
            value={item.InternalValue}
            displayvalue={item.DisplayValue}
            name={name}
          />
        )
      )}
    </div>
  </div>
);

BoxSec.propTypes = {
  title: PropTypes.string,
  items: PropTypes.any,
  name: PropTypes.string,
  tooltip: PropTypes.any,
  tooltipDirection: PropTypes.string,
};

export default BoxSec;
